$device-type: 'responsive';
$user-type: 'buyer';

@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

:local {
    .decorator {
        padding: 0 $sassy-spacing-x-small;
        display: flex;
        flex: 0 0 auto; // longhand flex-basis to support ie11
        justify-content: center;
        align-items: center;
        @include sassy-font-body($type: none);
    }

    .iconContainer {
        max-width: 32px;
        min-width: 32px;
        padding-right: $sassy-spacing-small;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .iconContainerSmall {
        max-width: 30px;
        width: 30px;
        padding-right: $sassy-spacing-x-small;
    }
    .iconContainerLarge {
        max-width: 45px;
        min-width: 45px;
    }

    .errorIcon {
        width: 100%;
        max-width: none !important;
        fill: $sassy-color-alert-primary;
        animation: fadeIn 0.15s ease-in;
    }

    .validatedIcon {
        width: 100%;
        max-width: none !important;
        fill: $sassy-color-success-primary;
        animation: fadeIn 0.15s ease-in;
    }

    .hiddenPlaceholder {
        display: none;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
