$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_spacing';

.container {
    position: relative;
    padding: 0 0 0 $sassy-spacing-medium;
    margin-bottom: $sassy-spacing-medium;

    @include sassy-breakpoint-below(tablet-portrait) {
        margin-bottom: 0;
    }
}

.title {
    margin: 0;
    @include sassy-breakpoint-below(tablet-portrait) {
        margin: $sassy-spacing-small 0;
    }
}
