$device-type: 'responsive';
$user-type: 'buyer';

@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_hidden';
@import '~dibs-sassy/exports/_focus';

$color-radio-primary: $sassy-color-noir;

@if $user-type == 'internal' {
    $color-radio-primary: $sassy-color-internal-primary;
} @else if $user-type == 'dealer' {
    $color-radio-primary: $sassy-color-dealer-primary;
}

.hiddenInput {
    @include sassy-visually-hidden;
}

// use native radio input focus to style custom radio button
.hiddenInput:global(.focus-visible) + .labelContainer .radioButton {
    @include sassy-focus-ring;
}
// use native radio input focus to style custom radio button
.hiddenInput:focus-visible + .labelContainer .radioButton {
    outline: none;
    @include sassy-focus-ring;
}

.labelContainer {
    position: relative;
    display: flex;
    align-items: top;
    cursor: pointer;
    color: $sassy-color-noir;
}

.labelContainerDisabled {
    cursor: not-allowed;
    color: $sassy-color-iron;
}

.radioButton {
    position: relative;
    background-color: $sassy-color-white;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    border: thin solid $sassy-color-dolphin;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    transition: all 0.05s linear;
    margin-top: 2px;
    overflow: hidden; // safari bug: FINDING-12903

    @if $user-type == 'internal' {
        height: 16px;
        width: 16px;
        margin-top: 3px;
    }
    &::after {
        content: '';
        width: 0;
        height: 0;
        border-radius: 100%;
        transition: all 0.05s linear;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: $color-radio-primary;
    }
}

.buttonChecked {
    border: thin solid $color-radio-primary;
    &::after {
        width: 12px;
        height: 12px;

        @if $user-type == 'internal' {
            width: 8px;
            height: 8px;
        }
    }
}

.buttonDisabled {
    border: thin solid $sassy-color-dolphin;
    background-color: $sassy-color-moonstone;
    &::after {
        background-color: $sassy-color-dolphin;
    }
}

.label {
    flex-grow: 1;
    @include sassy-font-body;
    margin-left: $sassy-spacing-small;
    line-height: 1.5;
}

.children {
    @include sassy-font-body;
    margin-left: 38px;
    margin-top: $sassy-spacing-x-small;

    @if $user-type == 'internal' {
        margin-left: 34px;
    }
}

.childrenDisabled {
    color: $sassy-color-iron;
}
