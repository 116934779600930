$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.container {
    padding: 0 0 0 $sassy-spacing-medium;

    @include sassy-breakpoint-below(tablet-landscape) {
        padding: 0 0 0 $sassy-spacing-x-small;
    }

    @include sassy-breakpoint-below(tablet-portrait) {
        padding: 0;
    }
}

.plusIcon {
    width: 15px;
    height: 15px;
    margin: 0 0 -2px 5px;
}

.refineWrapper {
    @include sassy-font-sub-header($type: normal);
    color: $sassy-color-buyer-primary;
    position: relative;
    cursor: pointer;
    padding: 0 $sassy-spacing-small $sassy-spacing-small;
}
