$device-type: 'responsive';
$user-type: 'buyer';

@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_hidden';
@import '~dibs-sassy/exports/_focus';

:local {
    //hide the actual checkbox
    .hiddenInput {
        @include sassy-visually-hidden;
    }

    // use native radio input focus to style custom checkbox
    .hiddenInput:global(.focus-visible) + .container .checkbox {
        @include sassy-focus-ring;
    }
    // use native radio input focus to style custom checkbox
    .hiddenInput:focus-visible + .container .checkbox {
        outline: none;
        @include sassy-focus-ring;
    }
    .container {
        display: flex;
        cursor: pointer;
        margin-bottom: 0; // Overwrite global css like bootstrap.
    }

    .container:hover .checkboxUnchecked {
        @if $user-type == 'internal' {
            border: thin solid $sassy-color-internal-primary;
        } @else if $user-type == 'dealer' {
            border: thin solid $sassy-color-dealer-primary;
        } @else {
            border: thin solid $sassy-color-noir;
        }
    }

    .checkboxChecked {
        @if $user-type == 'internal' {
            background: $sassy-color-internal-primary;
        } @else if $user-type == 'dealer' {
            background: $sassy-color-dealer-primary;
        } @else {
            background: $sassy-color-noir;
        }
    }

    .label {
        @include sassy-font-body;
        color: $sassy-color-noir;
        line-height: 1.5;
        margin-left: $sassy-spacing-small;

        &.small {
            @include sassy-font-body($size: x-small, $type: none);
            margin-left: 8px;
        }
        &.medium {
            @include sassy-font-body($size: small, $type: none);
            margin-left: $sassy-spacing-x-small;
        }
    }

    .labelContainerDisabled {
        cursor: not-allowed;
    }

    .labelDisabled {
        color: $sassy-color-iron;
    }

    .children {
        @include sassy-font-body;
        margin-left: 38px;

        @if $user-type == 'internal' {
            margin-left: 34px;
        }
    }

    .childrenDisabled {
        color: $sassy-color-iron;
    }

    .checkbox {
        display: flex;
        box-sizing: border-box;
        height: 20px;
        min-width: 20px;
        margin-top: 1px;

        &.small {
            height: 16px;
            min-width: 16px;
        }

        @if $user-type == 'internal' {
            min-width: 16px;
            height: 16px;
            margin-top: 3px;
        }
    }

    .checkboxUnchecked {
        border: thin solid $sassy-color-storm;
        background: $sassy-color-white;

        .checkmark {
            display: none;
        }
    }

    .checkmark {
        color: $sassy-color-white;
        fill: $sassy-color-white;
        margin: auto;
        width: 14px;
        height: 14px;

        &.small {
            width: 10px;
            height: 10px;
        }

        @if $user-type == 'internal' {
            width: 12px;
            height: 12px;
        }
    }

    .checkboxDisabled {
        background-color: $sassy-color-dolphin !important;
        border-color: $sassy-color-dolphin !important;
    }

    .circleCheckbox {
        border-radius: 50%;
    }

    .checkboxError {
        border-color: $sassy-color-alert-primary;
    }

    .container:hover .checkboxError {
        border-color: $sassy-color-alert-primary;
    }
}
