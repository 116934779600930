$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';

.modalBody {
    padding: 0 $sassy-spacing-large $sassy-spacing-large;

    @include sassy-breakpoint-below(tablet-portrait) {
        padding: 0 $sassy-spacing-small $sassy-spacing-small;
    }

    &.small {
        margin-top: $sassy-spacing-small;
    }

    &.medium {
        margin-top: $sassy-spacing-medium;
    }

    &.large {
        margin-top: $sassy-spacing-large;
    }
}
