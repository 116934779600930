$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.container {
    padding-top: $sassy-spacing-medium;
    padding-bottom: $sassy-spacing-medium;
    text-align: center;
    width: 100%;

    @include sassy-breakpoint-below(tablet-portrait) {
        margin-top: $sassy-spacing-medium;
    }
}

.pages {
    position: relative;
    display: inline-flex;

    @include sassy-breakpoint-below(tablet-portrait) {
        display: inline-block;
    }
}

.itemWrapper {
    @include sassy-font-body($size: small, $type: none);
    display: inline-block;
    width: 48px;
    text-align: center;
    margin-right: $sassy-spacing-small;
    color: $sassy-color-noir;

    @include sassy-breakpoint-below(tablet-landscape) {
        margin-right: 15px;
    }

    @include sassy-breakpoint-below(tablet-portrait) {
        margin-right: $sassy-spacing-x-small;
        width: 42px;
    }
}

.item {
    display: block;
    padding: $sassy-spacing-x-small 0;
    color: $sassy-color-noir;
}

.separator {
    width: $sassy-spacing-small;
}

.button {
    @include sassy-font-sub-header($type: normal);
    position: relative;
    text-align: center;

    &,
    &:hover {
        color: $sassy-color-noir;
    }
}

.link {
    @include sassy-buyer-link-no-decoration();
    border: 1px solid $sassy-color-storm;
    &:hover {
        border-color: $sassy-color-buyer-primary;
        cursor: pointer;
    }
}

.selected {
    border: 1px solid $sassy-color-storm;
    background-color: $sassy-color-moonstone;
}

.nextButton,
.previousButton {
    display: flex;
    align-items: center;
    bottom: calc(100% + #{$sassy-spacing-small});
    @include sassy-buyer-link-no-decoration();
}

.nextButton {
    @include sassy-breakpoint-below(tablet-portrait) {
        position: absolute;
        right: 0;
    }
}

.previousButton {
    margin-right: $sassy-spacing-small;
    @include sassy-breakpoint-below(tablet-portrait) {
        position: absolute;
        left: 0;
    }
}

.svgArrowWrapper {
    display: block;
    margin-top: -2px;
    width: 18px;
    height: 18px;
}
