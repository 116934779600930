$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

.container {
    padding-bottom: 14px;
}

.separator {
    border-bottom: 1px solid $sassy-color-dolphin;
}

.title {
    @include sassy-font-sub-header($type: normal);
    line-height: 1;
    padding: $sassy-spacing-small 0;
}
