$device-type: 'responsive';
$user-type: 'buyer';

@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_zindex';

.isHidden,
.isHidden + .triangle {
    visibility: hidden;
}

.isTransparent,
.isTransparent + .triangle {
    opacity: 0;
}

.container {
    display: block;
    position: absolute;

    @include sassy-z-index(bump);

    color: $sassy-color-noir;
    background: $sassy-color-white;
    box-shadow: 0 1px 5px rgba($sassy-color-noir, 0.3);
    cursor: default;
    transition: opacity;

    word-break: break-word;
    hyphens: auto;
}
.bump {
    @include sassy-z-index(bump);
}
.low {
    @include sassy-z-index(low);
}
.middle {
    @include sassy-z-index(middle);
}
.high {
    @include sassy-z-index(high);
}

.bottom,
.top {
    box-shadow: 1px 4px 15px 0 rgba($sassy-color-noir, 0.23);
}

.top + .triangle {
    transform: rotate(180deg) translateZ(0);
    filter: drop-shadow((-1px) -4px 2px $sassy-color-storm);
}

.left,
.right + .triangle {
    filter: drop-shadow(0 -3px 2px rgba($sassy-color-iron, 0.2));
}

.left + .triangle {
    transform: rotate(90deg) translateZ(0);
}

.right + .triangle {
    transform: rotate(-90deg) translateZ(0);
}

.large {
    width: 250px;
}

.xLarge {
    width: 444px;

    .inner {
        padding: $sassy-spacing-small;
    }
}

.autoWidth {
    min-width: 90px;

    .inner {
        white-space: nowrap;
    }
}

.small {
    min-width: 100px;
    white-space: nowrap;
    background: $sassy-color-noir;
    color: $sassy-color-white;

    .inner {
        @include sassy-font-body($size: x-small, $type: heavy);

        padding: 7px $sassy-spacing-x-small;
        letter-spacing: 0.5px;
    }

    + .triangle {
        .triangleIcon {
            fill: $sassy-color-noir;
        }
    }
}

.coachmark {
    width: 320px;
}

.medium {
    width: 240px;
}

// As the tooltip visibility is hidden while it's not displayed
// it will still take up space in viewport and might cause unwanted
// horizontal scroll on MW if its position wasn't adjusted correctly
// so to fix this the zero width should be added
.isHidden {
    width: 0;
    overflow: hidden;
}

.inner {
    @include sassy-font-body($size: small);

    position: relative;
    padding: 23px 27px;
    line-height: 1.4;
    text-align: left;

    &.smallPadding {
        padding: $sassy-spacing-x-small;
    }
    &.noPadding {
        padding: 0;
    }
}

.close {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 12px;
    height: 12px;
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
    fill: $sassy-color-iron;

    &:hover {
        opacity: 0.9;
        fill: $sassy-color-noir;
    }
}

.triangle {
    position: absolute;
    text-align: start;
}

.triangleIcon {
    position: inherit;
    max-height: 100%;
    max-width: 100%;
    fill: $sassy-color-white;
}

.triangleStroke {
    stroke: rgba($sassy-color-iron, 0.1);

    :local(.bottom) & {
        stroke: rgba($sassy-color-iron, 0.07);
    }
}
