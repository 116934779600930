$device-type: 'responsive';
$user-type: 'buyer';

@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/webkitLineClamp';

@mixin labelColor($primaryColor, $secondaryColor) {
    color: $primaryColor;
    &:hover {
        color: $secondaryColor;
    }
}

:local {
    .labelWrapper {
        display: flex;
        align-items: center;
        // button needs to fill expanding area width
        width: 100%;
        // remove default button text-align: center
        text-align: unset;
    }

    .label {
        flex: 1;
        flex-grow: 1;
    }

    .stringLabel {
        @include sassy-font-body;
        line-height: 1.5;
        @if $user-type == 'internal' {
            @include labelColor($sassy-color-internal-primary, $sassy-color-internal-secondary);
        } @else if $user-type == 'dealer' {
            @include labelColor($sassy-color-dealer-primary, $sassy-color-dealer-secondary);
        } @else {
            @include labelColor($sassy-color-noir, $sassy-color-charcoal);
        }
    }

    .arrow {
        flex-grow: 0;
        fill: currentColor;
        width: 16px;
        height: 16px;
        margin-left: $sassy-spacing-x-small;
        transition: transform 0.2s ease-in-out;
        transform: translateY(calc(50% - 8px));
    }

    .arrowUp {
        transform: rotate(180deg) translateY(calc(-50% + 8px));
    }

    .expandingArea {
        height: 0;
        overflow-y: auto;
        transition: height 0.2s linear;
        position: relative;
    }
    .noScroll {
        overflow-y: hidden;
    }

    .showOverflow {
        overflow: visible;
    }

    .gradient {
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
        width: 100%;
        height: $sassy-spacing-medium;
        @include sassy-z-index(bump);
    }

    .lineClamp {
        @supports (--cssVar: red) {
            @include webkitLineClamp(var(--expandingAreaLineClamp));
        }
    }
    .hideContent {
        visibility: hidden;
    }
}
