$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.filters {
    @include sassy-font-sub-header($type: normal);
    padding-bottom: $sassy-spacing-small;
}

.title {
    @include sassy-font-body($size: header-large);
    flex: 2 1 auto;
    line-height: 1;
    padding: $sassy-spacing-small 0 $sassy-spacing-medium $sassy-spacing-medium;
}
