$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_breakpoints';

.wrapper {
    display: flex;
    flex-direction: row;
    @include sassy-font-body($size: small);
}

.title {
    @include sassy-font-sub-header;
    @include sassy-breakpoint-below(tablet-portrait) {
        display: none;
    }
}

.imageWrapper {
    flex-basis: 0 0 auto;
}

.image {
    max-width: 400px;
    margin-bottom: $sassy-spacing-small;
    margin-right: $sassy-spacing-large;
    height: 220px;
    border-radius: 50%;
    @include sassy-breakpoint-below(tablet-portrait) {
        display: none;
    }
}
