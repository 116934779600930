$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.logoWrap {
    height: 35px;
    margin: $sassy-spacing-x-small auto;
    position: relative;

    &:after {
        content: '';
        display: block;
        width: 70px;
        height: 2px;
        margin: $sassy-spacing-x-small auto 0;
        background: $sassy-color-buyer-primary;
        position: relative;
        top: 40px;
    }
}

.wrap {
    height: 325px;
    padding: 0 0 $sassy-spacing-larger $sassy-spacing-small;

    @include sassy-breakpoint-below(tablet-portrait) {
        height: 330px;
    }
}

.container {
    display: block;
    max-width: 312px;
    height: 100%;
    border: 1px solid $sassy-color-moonstone;
    position: relative;
}

.imageLink {
    display: block;
}

.dealerLink {
    @include sassy-buyer-link-no-decoration();
}

.imageWrap {
    padding: 0;
    height: 114px;
    max-height: 114px;
    position: relative;
    overflow: hidden;

    @include sassy-breakpoint-below(tablet-portrait) {
        max-height: 105px;
    }
}

.image {
    display: block;
    max-height: 100%;
}

.logo {
    height: 100%;
}

.copy {
    @include sassy-font-body($size: small);
    color: $sassy-color-noir;
    max-height: 105px;
    padding: $sassy-spacing-medium $sassy-spacing-small 0;
    overflow: hidden;

    @include sassy-breakpoint-below(tablet-portrait) {
        max-height: 96px;
        padding: $sassy-spacing-small $sassy-spacing-x-small 0;
    }
}

.iconsWrapper {
    position: absolute;
    right: 15px;
    bottom: 20px;
    width: 50px;
    height: 20px;
    text-align: right;
    color: $sassy-color-noir;

    @include sassy-breakpoint-below(tablet-portrait) {
        bottom: $sassy-spacing-x-small;
    }
}

.icons {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 5px;
    position: relative;
}
