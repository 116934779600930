$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_breakpoints';

@mixin bootstrapOverride() {
    span,
    input,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'],
    input[type='search'],
    input[type='tel'],
    input[type='color'] {
        @content;
    }
}

:local {
    $input-width: 100%;
    $input-font-size: 16px;
    $input-line-height: 1.5;
    $input-color: $sassy-color-noir;
    $input-border: none;
    $input-border-radius: 0px;

    $input-animated-top: 15px;

    .textInputWrapper {
        height: 100%;

        @include bootstrapOverride {
            width: $input-width !important; //width: 100%;
            font-size: $input-font-size !important; //font-size: 14px;
            line-height: $input-line-height !important; //line-height: 1.42857;
            color: $input-color !important; //color: #555;
            vertical-align: middle !important;
            background-color: transparent !important; //background-color: #fff;
            background-image: none !important;
            border: $input-border !important; //border: 1px solid #ccc;
            border-radius: $input-border-radius; //border-radius: 2px;
            box-shadow: 0 0 0 30px $sassy-color-white inset !important; //box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
            transition: none !important; //transition: border-color ease-in-out 0.14s, box-shadow ease-in-out 0.15s;
            height: 100% !important; //height: 34px; See internal for additional change
            padding: 0 !important; //padding: 6px 12px;
            @include sassy-font-body($size: none);
            appearance: none;
            box-sizing: border-box;
            outline: none;
            text-overflow: ellipsis;
            position: relative;

            &:focus {
                border: none !important;
                border-color: transparent !important; //border-color: #66afe9;
                outline: none !important; //outline: 0;
                box-shadow: 0 0 0 30px $sassy-color-white inset !important; //box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
            }
            &:disabled {
                cursor: not-allowed;
                background-color: $sassy-color-dolphin !important;
                box-shadow: 0 0 0 30px $sassy-color-dolphin inset !important; //box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
            }
        }

        &.isHighlighted {
            @include bootstrapOverride {
                box-shadow: none !important;
            }
        }

        &.headerInput {
            @include bootstrapOverride {
                color: $sassy-color-pitch-black !important;
            }
        }

        input::placeholder {
            color: $sassy-color-satan;
        }
        input:read-only {
            cursor: pointer;
        }
    }

    .animatedPlaceholderPadding {
        @include bootstrapOverride {
            padding-top: $input-animated-top !important;
        }
    }
    .usePaddingLeft {
        @include bootstrapOverride {
            padding-left: 58px !important;
        }
    }
}
