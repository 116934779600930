$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

$padding: 5px;
$iconDimension: 20px;
$buttonDimension: #{$iconDimension + ($padding * 2)};

.closeButton {
    cursor: pointer;
    position: absolute;
    width: $buttonDimension;
    height: $buttonDimension;
    padding: $padding;
    top: 10px;
    right: 10px;
    // undo button style
    background: none;
    border: none;
    &.translucentWhite {
        background-color: rgba($sassy-color-white, 0.7);
    }
    svg {
        fill: $sassy-color-noir;
    }
}

.closeButtonDisabled {
    cursor: not-allowed;
}
