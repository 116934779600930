$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

.container {
    padding: $sassy-spacing-small;
    background-color: $sassy-color-alabaster;
    border: 1px solid $sassy-color-moonstone;

    @include sassy-breakpoint-below(tablet-portrait) {
        padding: $sassy-spacing-medium;
    }
}
