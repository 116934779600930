$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.label {
    @include sassy-font-body($size: small);
    margin-left: $sassy-spacing-x-small;
    margin-bottom: $sassy-spacing-x-small;
}

.link {
    @include sassy-buyer-link-no-decoration();
}
