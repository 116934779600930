$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
$user-type: 'buyer';

@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.button {
    @include sassy-font-body;
    text-decoration: underline;

    display: flex;
    align-items: center;
    // button needs to fill expanding area width
    width: 100%;
    // remove default button text-align: center
    text-align: unset;
}
