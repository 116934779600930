$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_zindex';

:local {
    .stopBodyScroll {
        overflow: hidden;

        // Fix'es iOS issue in modals
        @include sassy-breakpoint-below(tablet-landscape) {
            position: fixed !important; // On .com Google Translate adds some inline styles
        }
    }

    .overlayDefault {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sassy-z-index(above-overlay);
        background-color: $sassy-color-overlay-background;
        transition: all 0.3s ease;
    }

    .overlayFadeStart {
        opacity: 0;
    }

    .overlayDefaultOpening {
        opacity: 1;
    }

    .overlayDefaultClosing {
        opacity: 0;
    }

    .modalDefault {
        box-sizing: border-box;
        position: absolute;
        max-width: 100%;
        width: 540px;
        transform: translateX(-50%);
        top: $sassy-spacing-large;
        left: 50%;
        min-height: 250px;
        max-height: calc(100% - #{$sassy-spacing-xx-large});
        background-color: $sassy-color-white;
        color: $sassy-color-noir;
        overflow: auto;
        outline: none;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease;

        @include sassy-breakpoint-below(tablet-portrait) {
            width: 100%;
            top: 0;
            max-height: 100%;
            height: 100%;
        }
    }

    .modalMedium {
        @include sassy-breakpoint-above(tablet-portrait) {
            width: 720px;
        }
    }

    .modalLarge {
        @include sassy-breakpoint-above(tablet-portrait) {
            width: 960px;
        }
    }

    .modalFullscreen {
        @include sassy-breakpoint-above(tablet-portrait) {
            width: 100%;
            height: 100%;
            max-height: 100%;
            position: absolute;
            top: 0;
        }
    }

    .modalNoOverlay {
        @include sassy-z-index(above-overlay);
        position: fixed;
        transition: none;
    }

    .modalPositionBottom {
        top: auto;
        transform: translate(-50%, 0);
        @include sassy-breakpoint-above(tablet-portrait) {
            bottom: $sassy-spacing-large;
        }
        @include sassy-breakpoint-below(tablet-portrait) {
            bottom: 0;
        }
    }
    .modalPositionRightTop {
        top: $sassy-spacing-small;
        right: $sassy-spacing-small;
        transform: none;
        left: initial;

        @include sassy-breakpoint-below(tablet-portrait) {
            top: 0;
            right: 0;
        }
    }
    .modalPositionCenter {
        @include sassy-breakpoint-above(tablet-portrait) {
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .mobileModalSizeContentHeight {
        @include sassy-breakpoint-below(tablet-portrait) {
            bottom: 0;
            top: auto;
            height: auto;
            min-height: auto;
            max-height: calc(100vh - 90px);
            border-top-left-radius: 9px;
            border-top-right-radius: 9px;
        }
    }

    .slideBottom {
        bottom: 0;
        top: auto;
    }
    .slideBottomHidden {
        transform: translate(-50%, 100%);
    }
    .slideBottomVisible {
        transform: translate(-50%, 0);
    }

    .slideRight {
        top: 0;
        right: 0;
        left: auto;
        height: 100%;
        max-height: 100%;
    }
    .slideRightHidden {
        transform: translate(100%, 0%);
    }
    .slideRightVisible {
        transform: translate(0%, 0%);
    }

    .modalHidden {
        display: none;
    }
}
